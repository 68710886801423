import {
  ANSWER_UPDATED,
  ANSWER_VALIDATED,
  IS_NEW_CONTENT_SET,
  CONQUEST_STATE_RESET
} from './conquest.actions';

export const initialState = {
  isNewContent: false,
  answer: {},
  isReady: false,
  validation: null,
  keypadRef: null,
  isKeypadActivated: true
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ANSWER_UPDATED: {
      return {
        ...state,
        ...action.payload
      };
    }

    case ANSWER_VALIDATED: {
      return {
        ...state,
        validation: action.validation
      };
    }

    case IS_NEW_CONTENT_SET: {
      return {
        ...state,
        isNewContent: action.isNewContent
      };
    }

    case CONQUEST_STATE_RESET: {
      return {
        ...state,
        answer: {},
        isReady: false,
        validation: null
      };
    }

    default:
      return state;
  }
};

exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".correct-answer---3NiQy{border-radius:20px;border:1px solid #bcedbd;margin-top:20px}.correct-answer---3NiQy .correct-answer-text---2MhDp{background-color:#f6fef4;text-align:center;border-radius:20px 20px 0 0;padding:10px 0}.correct-answer---3NiQy .correct-answer-container---1p-HG{padding:10px}.correct-answer---3NiQy *[data-type=\"default-content\"]{display:none}.read-aloud-container---3MZVj{position:absolute;right:0;top:0;z-index:95}.read-aloud-container---3MZVj .chakra-button{display:table;background-color:rgba(255,255,255,.6);border:none;width:55px;height:55px;filter:drop-shadow(rgba(0, 25, 55, 0.2) -5px 5px 5px)}.read-aloud-container---3MZVj .chakra-button:hover{background-color:#fff}.chakra-button.keypad-btn---1oFLo{display:table;position:absolute;right:0;bottom:0;z-index:96;width:55px;height:55px;background-color:rgba(255,255,255,.6);filter:drop-shadow(rgba(0, 25, 55, 0.2) -5px -5px 5px);overflow:hidden;border-radius:55px}.chakra-button.keypad-btn---1oFLo:hover{background-color:#fff}.chakra-button.keypad-btn---1oFLo .chakra-image{width:34px;max-width:none;position:absolute;left:10px;top:18px}.chakra-button.keypad-btn---1oFLo.keypad-activated---18epP{background-color:#fff;border:1px solid rgba(52,119,199,.4)}.read-aloud-button{left:50px}.ML__keyboard span{font-size:unset}", ""]);

// exports
exports.locals = {
	"correct-answer": "correct-answer---3NiQy",
	"correct-answer-text": "correct-answer-text---2MhDp",
	"correct-answer-container": "correct-answer-container---1p-HG",
	"read-aloud-container": "read-aloud-container---3MZVj",
	"keypad-btn": "keypad-btn---1oFLo",
	"keypad-activated": "keypad-activated---18epP"
};
import React, { useEffect, useState, useContext } from 'react';
import { QtcRenderer, ReadAloud, KeypadContext } from '@3plearning/question-components-library';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Img, Text } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import { updateAnswer } from './conquest.actions';
import css from './conquest.scss';
import { useLocalisation } from '../../helpers/i18n/use-localisation';
import { useReadAloud } from './hooks/use-read-aloud';
import keypadIcon from '../../assets/images/keypad.svg';
import { getValueFromUrl } from '../../helpers/utils';
import { activityLoaded } from '../activity/activity-actions';
import { disableSpinner } from '../mle/mle-actions';
import { LEARNOSITY_SPINNER } from '../../helpers/constants';

const Conquest = () => {
  const { i18n } = useLocalisation();
  const dispatch = useDispatch();
  const [question, setQuestion] = useState([]);
  const [keyId, setKeyId] = useState('');
  const { activityReducer, conquestReducer } = useSelector(state => state);
  const { questions } = activityReducer;
  const { readAloudConfig, onLocaleChange, onToggleEnableState } = useReadAloud();
  const {
    keypadRef, showKeypad, setShowKeypad, showKeypadBtn, isKeypadBtnDisabled
  } = useContext(KeypadContext);
  const keypadPresetFromQueryParams = getValueFromUrl('keypadPreset');

  const feedback = ({ answer, isReady }) => {
    if (isReady) {
      dispatch(updateAnswer({ answer, isReady }));
    }
  };

  const toggleKeypad = (event) => {
    event.preventDefault();
    setShowKeypad(!showKeypad);
  };

  useEffect(() => {
    if (questions.length > 0) {
      setQuestion(questions[0].conquest.questionVariant.questionItem.questionContent.content);
      setKeyId(questions[0].conquest.questionVariant.id);
      dispatch(activityLoaded());
      dispatch(disableSpinner(LEARNOSITY_SPINNER));
    }
  }, [questions]);

  return (
    <Box>
      <Box className={css['read-aloud-container']}>
        <ReadAloud
          onLocaleChange={locale => onLocaleChange(locale)}
          onToggleEnableState={enabled => onToggleEnableState(enabled)}
          popoverLabel={i18n._(t`Read aloud`)}
          selectedLocale={/es-/ig.test(getValueFromUrl('locale')) ? 'es-ES' : getValueFromUrl('locale')}
          disabled
        />
      </Box>
      {showKeypadBtn && <Button
        className={`${css['keypad-btn']} ${showKeypad && !isKeypadBtnDisabled && css['keypad-activated']}`}
        onMouseDown={toggleKeypad}
        isDisabled={isKeypadBtnDisabled}
      >
        <Img src={keypadIcon} />
      </Button>}
      <Box key={keyId}>
        {keypadRef.current && <QtcRenderer
          question={question}
          callback={feedback}
          validation={conquestReducer.validation}
          keypadPreset={
            keypadPresetFromQueryParams ? Number(keypadPresetFromQueryParams) : 3
          }
          keypadContainer={keypadRef.current}
          readAloudConfig={readAloudConfig}
          userResponse={conquestReducer.answer}
        />}
      </Box>
      {
        conquestReducer.validation && !conquestReducer.validation.isCorrect && (
          <Box className={css['correct-answer']} >
            <Text className={css['correct-answer-text']} >
              {i18n._(t`Here's the correct answer`)}
            </Text>
            <Box className={css['correct-answer-container']}>
              <QtcRenderer
                callback={() => undefined}
                question={question}
                showCorrectAnswer
                validation={conquestReducer.validation}
              />
            </Box>
          </Box>
        )
      }
    </Box>
  );
};

export default Conquest;
